<template>
    <v-row>
        <template v-for="(post, index) in posts">
            <v-col :key="index + '_adkey'"
                   v-if="index % 4 === 0"
                   cols="12" sm="6" md="6" lg="4" :xl="$root.windowWidth < 2200 ? 3 : $root.windowWidth < 3800 ? 2 : 1" class="px-0 px-sm-2">
                <GoogleAdSense></GoogleAdSense>
            </v-col>
            <v-col :key="index + '_postkey'"
                   cols="12" sm="6" md="6" lg="4" :xl="$root.windowWidth < 2200 ? 3 : $root.windowWidth < 3800 ? 2 : 1" class="px-0 px-sm-2">
                <post :post="post"
                      @voted="voted"
                      @open_post="open_post"></post>
            </v-col>
        </template>
    </v-row>
</template>

<script>
    export default {
        props  : ['posts'],
        methods: {
            // Emits for parent
            voted(postid, action, points) {
                this.$emit("voted", postid, action, points);
            },
            open_post(postid) {
                this.$emit('open_post', postid);
            }
        }
    }
</script>