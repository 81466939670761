<template>
    <div :style="'width:' + !$parent.c_is_mobile_search ? '300px' : '100%'">
        <v-text-field
                v-model="search_term"
                :label="$t('post-pages.search-term')"
                :color="$variables.v.theme.secondary"
                outlined
                dense
                clearable
                maxlength="70"
                width="200"
                append-icon="mdi-magnify"
                @click:clear="clickedClear"
                @click:append="set_search_term"
                @keyup.enter="set_search_term">
        </v-text-field>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                search_term: null,
            }
        },
        methods: {
            set_search_term() {
                this.$emit("search", this.search_term.trim());
            },
            clickedClear() {
                this.search_term = "";
                this.$emit("search", this.search_term.trim())
            },
        },
    }
</script>