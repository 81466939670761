import post_page from "../../components/post-page/post-page"


export default {
    components: {
        "post-page": post_page
    },
    computed  : {
        c_is_mobile_search() {
            return this.$root.windowWidth < 500;
        },
    },
    data() {
        return {
            posts             : [],
            shown_post        : null,
            loading           : false,
            no_posts_available: false,
            no_posts_left     : false,
            search_term       : "",
            userid            : null,
            newest_post_id    : null,
        }
    },
    created() {
        this.init();
    },
    watch     : {
        loading() {
            if (!this.loading)
                if (this.posts.length !== 0)
                    window.addEventListener('scroll', this.scroll);
                else
                    this.no_posts_available = true;
        },
    },
    methods   : {
        async init() {
            // Warnung wenn keine Services in DB Gefunden
            if (this.$variables.v.services.length === 0 || this.$variables.v.itemtypes.length === 0) {
                this.eventbus.$emit("SB", "post-pages.msg.error-no-services-itemtypes-available", "error");
                return;
            }

            // Warnung wenn alle Services oder Itemtypen deaktiviert sind
            if (this.$variables.v.services.length === this.$user.user.hidden_services.length || this.$variables.v.itemtypes.length === this.$user.user.hidden_itemtypes.length) {
                this.eventbus.$emit("SB", "post-pages.msg.everything-disabled", "warning");
                return;
            }

            this.get_posts(true);

            setTimeout(async () => {
                    if (this.$route.params.postid) // Open Post if URL contains a Postid
                        await this.$refs.dialog_post.open_dialog(this.$route.params.postid);
                }, 1000
            );
        },
        set_search_term(st) {
            this.search_term = st;
            this.refresh_posts();
        },
        refresh_posts() {
            this.posts         = []; // CLEAR POSTS
            this.no_posts_left = false;
            this.get_posts();
        },
        build_postdata() {
            // OVERRIDDEN
        },
        async get_posts(set_newest_posts = false) {
            this.loading = true;

            let response = await this.func.ax("/php/getter.php", this.build_postdata());
            if (response.length !== 0)
                this.posts = this.posts.concat(response); // Merge Arrays

            setTimeout(() => {
                this.no_posts_left = response.length < this.$variables.v.max_posts_per_load;

                if (!this.no_posts_left && (window.innerHeight + window.scrollY) >= document.body.offsetHeight)
                    this.get_posts();
            }, 1000);

            if (set_newest_posts)
                this.newest_post_id = this.posts.length > 0 ? this.posts[0].ID : null;

            this.loading = false;
        },
        checkNearBottom() {
            let nearbottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight - 300;
            if (nearbottomOfWindow && !this.no_posts_left && !this.loading) {
                this.get_posts();
                return true;
            }
            else
                return false;
        },
        scroll() {
            window.onscroll = () => { // When scrolling and near the bottom of page than load more
                this.checkNearBottom();
            };
        },
        update_post(postid, action, points) {
            this.posts.find(x => x.ID === postid).action = action;
            this.posts.find(x => x.ID === postid).points = points;
        },
        remove_post_from_posts(postid) {
            let removeIndex = this.posts.map(function (item) {
                return item.ID;
            }).indexOf(postid);
            this.posts.splice(removeIndex, 1);
        },
    },
}