<template>
    <div v-if="$store.cookie_permission">
        <div :class="{'d-flex': !is_mobile_search, 'mb-5 text-center':is_mobile_search}">
            <post-page-search @search="search" :class="{'mr-5':!is_mobile_search}"></post-page-search>
            <dialog-create-post-selector @post_created="$emit('post_created')"></dialog-create-post-selector>
            <div v-if="!$user.user.auth" class="mt-sm-2">{{$t("post-pages.sign-in-for-filter")}}</div>
        </div>
        <v-divider class="mb-5"></v-divider>

        <post-page-row :posts="posts"
                       @voted="voted"
                       @open_post="open_post"></post-page-row>

        <v-col v-if="no_posts_left && !no_posts_available">{{$t('post-pages.end-of-page')}}</v-col>

        <v-col v-if="no_posts_available">{{$t('post-pages.no-posts-available')}}</v-col>

        <dialog-post ref="dialog_post" @post_not_found="post_not_found" @voted="voted"></dialog-post>

        <loading-circle v-if="loading"></loading-circle>
    </div>
</template>

<script>
    import dialog_create_post_selector from "../../components/dialogs/create-post/dialog-create-post-selector"
    import post_page_row from ".//post-page-row"
    import post_page_search from ".//post-page-search"


    export default {
        components: {
            "dialog-create-post-selector": dialog_create_post_selector,
            "post-page-row"              : post_page_row,
            "post-page-search"           : post_page_search
        },
        props     : ['is_mobile_search', 'posts', 'no_posts_left', 'no_posts_available', 'loading'],
        created() {
            this.init();
        },
        methods   : {
            async init() {
                setTimeout(async () => {
                        if (this.$route.params.postid) // Open Post if URL contains a Postid
                            await this.$refs.dialog_post.open_dialog(this.$route.params.postid);
                    }, 1000
                );
            },

            // Emits for parent
            voted(postID, action, points) {
                this.$emit("voted", postID, action, points);
            },
            post_not_found(postid) {
                this.$emit("post_not_found", postid);
            },
            open_post(postid) {
                this.$refs.dialog_post.open_dialog(postid);
            },
            search(searchterm) {
                this.$emit('search', searchterm);
            }
        }
    }
</script>