<template>
    <v-dialog v-model="dialog" width="max-content">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="$user.user.auth
                            && $user.user.roleID !== $variables.v.role_ids.leecher"
                   :color="$variables.v.theme.primary"
                   depressed
                   v-bind="attrs"
                   v-on="on">
                {{$t('create-post.create-post')}}
            </v-btn>
        </template>

        <dialog-create-post-giveaway ref="dialog_create_post_giveaway" @post_created="$emit('post_created')"></dialog-create-post-giveaway>
        <dialog-create-post-deal ref="dialog_create_post_deal" @post_created="$emit('post_created')"></dialog-create-post-deal>

        <v-card class="px-4 grey darken-3">

            <div class="post-create-selector-headline pt-3">{{$t('create-post.post-type')}}</div>

            <div class="d-flex">
                <v-card flat
                        color="amber"
                        class="post-create-selector-card disable-select ma-3"
                        @click="open_create_giveaway">
                    <div class="text-center mt-6">
                        <v-icon color="black">mdi-gift-outline</v-icon>
                    </div>
                    <div class="post-create-selector-text mt-2">
                        {{$t('create-post.giveaway')}}
                    </div>
                </v-card>

                <v-card flat
                        color="red darken-4"
                        class="post-create-selector-card disable-select ma-3"
                        @click="open_create_deal">
                    <div class="text-center mt-6">
                        <v-icon color="black">mdi-currency-usd</v-icon>
                    </div>
                    <div class="post-create-selector-text mt-2">
                        {{$t('create-post.deal')}}
                    </div>
                </v-card>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    import dialog_create_post_giveaway from "./dialog-create-post-giveaway"
    import dialog_create_post_deal from "./dialog-create-post-deal"


    export default {
        components: {
            "dialog-create-post-giveaway": dialog_create_post_giveaway,
            "dialog-create-post-deal"    : dialog_create_post_deal
        },
        data() {
            return {
                dialog: false,
            }
        },
        methods   : {
            open_create_giveaway() {
                this.$refs.dialog_create_post_giveaway.open_dialog();
                this.dialog = false;
            },
            open_create_deal() {
                this.$refs.dialog_create_post_deal.open_dialog();
                this.dialog = false;
            },
        }
    }

</script>