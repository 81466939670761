<template>
    <v-dialog v-model="dialog" width="800px">
        <v-form v-model="valid" ref="r_form_create_post" class="post-create-form grey darken-3">
            <dragger></dragger>

            <v-row>
                <v-col cols="12">
                    <dialog-create-post-guidelines></dialog-create-post-guidelines>
                </v-col>

                <v-col cols="12" :sm="([5, 6].indexOf(this.itemtypeid) >= 0) ? 12 : 6">
                    <span class="disable-select">{{$t("create-post.itemtype")}}</span>
                    <v-select
                            v-model="itemtypeid"
                            :items="sorted_itemtypes"
                            item-value="id"
                            :color="$variables.v.theme.secondary"
                            dense
                            single-line
                            outlined>
                        <template slot=selection slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                        <template slot=item slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" sm="6" v-if="!([5, 6].indexOf(this.itemtypeid) >= 0)">
                    <span class="disable-select">{{$t("create-post.service")}}</span>
                    <v-select
                            v-model="serviceid"
                            :items="sorted_services"
                            :color="$variables.v.theme.secondary"
                            item-value="id"
                            dense
                            single-line
                            outlined>
                        <template slot=selection slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                        <template slot=item slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="itemtypeid === 2" cols="12" sm="6">
                    <v-text-field v-model="base_game_url"
                                  :label="$t('create-post.base-game-url')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  counter
                                  dense
                                  maxlength="300"></v-text-field>
                </v-col>
                <v-col v-if="itemtypeid === 2" cols="12" sm="6">
                    <v-checkbox class="mr-3"
                                v-model="base_game_not_free"
                                :label="$t('create-post.payed-base-game')"
                                :color="$variables.v.theme.secondary"></v-checkbox>
                </v-col>

                <v-col cols="6">
                    <v-checkbox class="mr-3"
                                v-model="vpn_required"
                                :label="$t('create-post.vpn-required')"
                                :color="$variables.v.theme.secondary"></v-checkbox>
                </v-col>


                <v-col cols="3">
                    <v-text-field v-model="deal_discount_in_percent"
                                  :label="$t('create-post.price-discount-percent')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense
                                  append-icon="mdi-percent-outline"
                                  :rules="[rules.percentage]"></v-text-field>
                </v-col>


                <v-col cols="3">
                    <v-text-field v-model="deal_currency"
                                  :label="$t('create-post.price-currency')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense
                                  counter
                                  maxlength="7"></v-text-field>
                </v-col>


                <v-col cols="6">
                    <v-text-field v-model="deal_price_original"
                                  :label="$t('create-post.price-original')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense
                                  @focusout="calc_discount"
                                  :rules="[rules.required, rules.max7, c_is_number_original]"
                                  maxlength="7"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="deal_price_reduced"
                                  :label="$t('create-post.price-reduced')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense
                                  @focusout="calc_discount"
                                  :rules="[rules.required, rules.max7, c_is_number_reduced]"
                                  maxlength="7"></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-text-field v-model="post_title"
                                  :label="$t('create-post.post-title')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  counter
                                  :rules="[rules.required, rules.min5, rules.max80]"
                                  maxlength="70"></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-textarea v-model="post_description"
                                :label="$t('create-post.post-description')"
                                maxlength="2000"
                                :rules="[rules.max2000]"
                                :color="$variables.v.theme.secondary"
                                counter
                                outlined
                                auto-grow
                                no-resize
                                rows="3"></v-textarea>
                </v-col>


                <v-col cols="12" md="6">
                    <v-text-field v-model="post_offer_url"
                                  :label="$t('create-post.post-offer-url')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense
                    ></v-text-field>

                    <v-text-field v-model="post_picture_url"
                                  :label="$t('create-post.post-picture-url')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense></v-text-field>
                </v-col>


                <v-col cols="12" md="6">
                    <img :src="post_picture_url"
                         style="max-height:130px"
                         class="post-image"
                         alt=""
                         loading="lazy">
                </v-col>


                <v-col cols="12" sm="6">
                    <v-dialog
                            ref="dialog"
                            v-model="modal_date"
                            :return-value.sync="expiration_date"
                            width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="expiration_date"
                                    :label="$t('create-post.post-expiration-date')"
                                    :color="$variables.v.theme.secondary"
                                    outlined
                                    readonly
                                    clearable
                                    dense
                                    v-bind="attrs"
                                    v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="expiration_date"
                                       :min="current_date"
                                       @change="$refs.r_form_create_post.validate()"
                                       scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text
                                   :color="$variables.v.theme.secondary"
                                   @click="modal_date = false">{{$t("date-time-modal.cancel")}}
                            </v-btn>
                            <v-btn text
                                   :color="$variables.v.theme.secondary"
                                   @click="$refs.dialog.save(expiration_date)">
                                {{$t("date-time-modal.ok")}}
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-dialog
                            ref="dialog2"
                            v-model="modal_time"
                            :return-value.sync="expiration_time"
                            width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="expiration_time"
                                    :label="$t('create-post.post-expiration-time')"
                                    :color="$variables.v.theme.secondary"
                                    outlined
                                    readonly
                                    clearable
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[c_check_expiration_date]"></v-text-field>
                        </template>
                        <v-time-picker
                                v-if="modal_time"
                                v-model="expiration_time"
                                full-width>
                            <v-spacer></v-spacer>
                            <v-btn text
                                   :color="$variables.v.theme.secondary"
                                   @click="modal_time = false">{{$t("date-time-modal.cancel")}}
                            </v-btn>
                            <v-btn text
                                   :color="$variables.v.theme.secondary"
                                   @click="$refs.dialog2.save(expiration_time)">
                                {{$t("date-time-modal.ok")}}
                            </v-btn>
                        </v-time-picker>
                    </v-dialog>
                </v-col>


                <v-col cols="12">
                    <div class="text-center">
                        <dialog-create-post-similar-posts :validbutton="valid"
                                                          :post_title="post_title"
                                                          :post_offer_url="post_offer_url"
                                                          :offer_type="$variables.v.offer_types.deal"
                                                          @confirmed="create_post"></dialog-create-post-similar-posts>
                    </div>
                </v-col>
            </v-row>
        </v-form>
    </v-dialog>
</template>

<script>
    import template from "./dialog-create-post-giveaway";


    export default {
        extends : template,
        data() {
            return {
                offer_type              : this.$variables.v.offer_types.deal,
                deal_discount_in_percent: "",
                deal_currency           : "€",
                deal_price_original     : "",
                deal_price_reduced      : "",
                rules                   : {
                    percentage: v => (v !== undefined && (v.length === 0 || (parseInt(v) >= 1 && parseInt(v) <= 99))) || this.$t("rules.percentage"),
                    max7      : v => (v !== undefined && v.length <= 7) || this.$t("rules.max-7-chars")
                },
            }
        },
        beforeCreate() {
            this.func.vuetify_dragable_dialogs();
        },
        methods : {
            calc_discount() {
                this.deal_discount_in_percent = String(100 - Math.round(100 / this.deal_price_original.replace(',', '.') * this.deal_price_reduced.replace(',', '.')));
            },
            build_postdata() {
                let postdata        = {};
                postdata.offer_type = this.offer_type;
                postdata.itemtypeid = this.itemtypeid;

                if (this.itemtypeid === 2) { //Is a DLC
                    postdata.base_game_url      = this.func.add_http(this.base_game_url.trim());
                    postdata.base_game_not_free = this.base_game_not_free;
                }
                if (!([5, 6].indexOf(this.itemtypeid) >= 0))
                    postdata.serviceid = this.serviceid;

                // Tag options
                postdata.vpn_required = this.vpn_required;

                postdata.deal_discount_in_percent = this.deal_discount_in_percent.trim();
                postdata.deal_currency            = this.deal_currency;
                postdata.deal_price_original      = this.deal_price_original.trim().replace(',', '.');
                postdata.deal_price_reduced       = this.deal_price_reduced.trim().replace(',', '.');

                postdata.title       = this.post_title.trim();
                postdata.description = this.post_description.trim();

                postdata.offer_url   = this.func.add_http(this.post_offer_url.trim());
                postdata.picture_url = this.post_picture_url.trim();

                postdata.expiration_date = this.func.get_date_time(this.expiration_date, this.expiration_time);

                postdata.userid = this.$user.user.id;
                postdata.token  = this.$user.user.token;

                return postdata;
            },
            reset_create_post() {
                this.valid                    = false;
                this.current_date             = null;
                //this.vpn_required             = false;
                this.key_giveaway             = false;
                this.base_game_url            = "";
                this.base_game_not_free       = false;
                this.deal_price_original      = "";
                this.deal_price_reduced       = "";
                this.deal_discount_in_percent = "";
                this.deal_currency            = null;
                this.post_title               = "";
                this.post_description         = "";
                this.post_offer_url           = "";
                this.post_picture_url         = "";
                this.post_time_end            = "";
                this.expiration               = "";
                this.expiration_date          = null;
                this.expiration_time          = null;
            },
        },
        computed: {
            c_is_number_original() {
                return () => (this.deal_price_original !== undefined && !isNaN(this.deal_price_original.replace(',', '.'))) || this.$t("rules.not-number");
            },
            c_is_number_reduced() {
                return () => (this.deal_price_reduced !== undefined && !isNaN(this.deal_price_reduced.replace(',', '.'))) || this.$t("rules.not-number");
            },
        },
    }
</script>