<template>
    <v-dialog v-model="dialog" width="800px">
        <v-form v-model="valid" ref="r_form_create_post" class="post-create-form grey darken-3">
            <dragger></dragger>

            <v-row>
                <v-col cols="12">
                    <dialog-create-post-guidelines></dialog-create-post-guidelines>
                </v-col>

                <v-col cols="12" :sm="([5, 6].indexOf(this.itemtypeid) >= 0) ? 12 : 6">
                    <span class="disable-select">{{$t("create-post.itemtype")}}</span>
                    <v-select
                            v-model="itemtypeid"
                            :items="sorted_itemtypes"
                            item-value="id"
                            :color="$variables.v.theme.secondary"
                            dense
                            single-line
                            outlined>
                        <template slot=selection slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                        <template slot=item slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" sm="6" v-if="!([5, 6].indexOf(this.itemtypeid) >= 0)">
                    <span class="disable-select">{{$t("create-post.service")}}</span>
                    <v-select
                            v-model="serviceid"
                            :items="sorted_services"
                            :color="$variables.v.theme.secondary"
                            item-value="id"
                            dense
                            single-line
                            outlined>
                        <template slot=selection slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                        <template slot=item slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="itemtypeid === 2" cols="12" sm="6">
                    <v-text-field v-model="base_game_url"
                                  :label="$t('create-post.base-game-url')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  counter
                                  dense
                                  maxlength="300"></v-text-field>
                </v-col>
                <v-col v-if="itemtypeid === 2" cols="12" sm="6">
                    <v-checkbox v-model="base_game_not_free"
                                :label="$t('create-post.payed-base-game')"
                                :color="$variables.v.theme.secondary"></v-checkbox>
                </v-col>


                <v-col cols="6">
                    <v-checkbox v-model="vpn_required"
                                :label="$t('create-post.vpn-required')"
                                :color="$variables.v.theme.secondary"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox v-model="key_giveaway"
                                :label="$t('create-post.key-giveaway')"
                                :color="$variables.v.theme.secondary"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox v-model="unknown_expiration"
                                :label="$t('create-post.unknown-expiration')"
                                :color="$variables.v.theme.secondary"></v-checkbox>
                </v-col>


                <v-col cols="12">
                    <v-text-field v-model="post_title"
                                  :label="$t('create-post.post-title')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  counter
                                  :rules="[rules.required, rules.min5, rules.max80]"
                                  maxlength="70"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-textarea v-model="post_description"
                                :label="$t('create-post.post-description')"
                                maxlength="2000"
                                :rules="[rules.max2000]"
                                :color="$variables.v.theme.secondary"
                                counter
                                outlined
                                auto-grow
                                no-resize
                                rows="3"></v-textarea>
                </v-col>


                <v-col cols="12" md="6">
                    <v-text-field v-model="post_offer_url"
                                  :label="$t('create-post.post-offer-url')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense
                    ></v-text-field>

                    <v-text-field v-model="post_picture_url"
                                  :label="$t('create-post.post-picture-url')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense></v-text-field>
                </v-col>


                <v-col cols="12" md="6">
                    <img :src="post_picture_url"
                         style="max-height:130px"
                         class="post-image"
                         alt=""
                         loading="lazy">
                </v-col>


                <v-col cols="12" sm="6" v-if="!unknown_expiration">
                    <v-dialog
                            ref="dialog"
                            v-model="modal_date"
                            :return-value.sync="expiration_date"
                            width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="expiration_date"
                                    :label="$t('create-post.post-expiration-date')"
                                    :color="$variables.v.theme.secondary"
                                    outlined
                                    readonly
                                    clearable
                                    dense
                                    v-bind="attrs"
                                    v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="expiration_date"
                                       :min="current_date"
                                       @change="$refs.r_form_create_post.validate()"
                                       scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text
                                   :color="$variables.v.theme.secondary"
                                   @click="modal_date = false">{{$t("date-time-modal.cancel")}}
                            </v-btn>
                            <v-btn text
                                   :color="$variables.v.theme.secondary"
                                   @click="$refs.dialog.save(expiration_date)">{{$t("date-time-modal.ok")}}
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>

                <v-col cols="12" sm="6" v-if="!unknown_expiration">
                    <v-dialog
                            ref="dialog2"
                            v-model="modal_time"
                            :return-value.sync="expiration_time"
                            width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="expiration_time"
                                    :label="$t('create-post.post-expiration-time')"
                                    :color="$variables.v.theme.secondary"
                                    outlined
                                    readonly
                                    clearable
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[c_check_expiration_date]"></v-text-field>
                        </template>
                        <v-time-picker
                                v-if="modal_time"
                                v-model="expiration_time"
                                full-width>
                            <v-spacer></v-spacer>
                            <v-btn text
                                   :color="$variables.v.theme.secondary"
                                   @click="modal_time = false">{{$t("date-time-modal.cancel")}}
                            </v-btn>
                            <v-btn text
                                   :color="$variables.v.theme.secondary"
                                   @click="$refs.dialog2.save(expiration_time)">{{$t("date-time-modal.ok")}}
                            </v-btn>
                        </v-time-picker>
                    </v-dialog>
                </v-col>


                <v-col cols="12">
                    <div class="text-center">
                        <dialog-create-post-similar-posts :validbutton="valid"
                                                          :post_title="post_title"
                                                          :post_offer_url="post_offer_url"
                                                          :offer_type="$variables.v.offer_types.giveaway"
                                                          @confirmed="create_post"></dialog-create-post-similar-posts>
                    </div>
                </v-col>
            </v-row>
        </v-form>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                dialog            : false,
                valid             : false, // Disable Button when form not valid
                sorted_itemtypes  : [],
                sorted_services   : [],
                current_date      : null,
                offer_type        : this.$variables.v.offer_types.giveaway,
                itemtypeid        : null,
                serviceid         : null,
                vpn_required      : false,
                key_giveaway      : false,
                unknown_expiration: false,
                base_game_url     : "",
                base_game_not_free: false,
                post_title        : "",
                post_description  : "",
                post_offer_url    : "",
                post_picture_url  : "",
                post_time_end     : "",
                modal_date        : false,
                modal_time        : false,
                expiration        : "",
                expiration_date   : null,
                expiration_time   : null,
                rules             : {
                    required: v => (!!v) || this.$t("rules.required"),
                    min5    : v => (v !== undefined && v.length >= 5) || this.$t("rules.min-5-chars"),
                    max80   : v => (v !== undefined && v.length <= 80) || this.$t("rules.max-80-chars"),
                    max2000 : v => (v !== undefined && v.length <= 2000) || this.$t("rules.max-2000-chars"),
                },
            }
        },
        computed: {
            c_check_expiration_date() {
                return () => this.expiration_date !== null || this.expiration_time === null || this.$t("rules.date-required");
            },
        },
        async created() {
            this.func.vuetify_dragable_dialogs();
            this.sorted_itemtypes = this.$variables.v.itemtypes.sort(this.func.compare_by_pos);
            this.sorted_services  = this.$variables.v.services.sort(this.func.compare_by_pos);
        },
        methods : {
            open_dialog() {
                this.dialog = true;
            },
            build_postdata() {
                let postdata        = {};
                postdata.offer_type = this.offer_type;
                postdata.itemtypeid = this.itemtypeid;

                if (this.itemtypeid === 2) { //Is a DLC
                    postdata.base_game_url      = this.func.add_http(this.base_game_url.trim());
                    postdata.base_game_not_free = this.base_game_not_free;
                }
                if (!([5, 6].indexOf(this.itemtypeid) >= 0))
                    postdata.serviceid = this.serviceid;

                // Tag options
                postdata.vpn_required = this.vpn_required;
                postdata.key_giveaway = this.key_giveaway;

                postdata.title       = this.post_title.trim();
                postdata.description = this.post_description.trim();

                postdata.offer_url   = this.func.add_http(this.post_offer_url.trim());
                postdata.picture_url = this.post_picture_url.trim();

                // When expiration date is unknown set it to null
                postdata.expiration_date = this.unknown_expiration ? null : this.func.get_date_time(this.expiration_date, this.expiration_time);

                postdata.userid = this.$user.user.id;
                postdata.token  = this.$user.user.token;

                return postdata;
            },
            async create_post() {
                if (this.$refs.r_form_create_post.validate()) {
                    if (this.itemtypeid === null) {
                        this.eventbus.$emit("SB", "create-post.msg.no-itemtype-set", "warning");
                        return;
                    }

                    let response = await this.func.ax("/php/post_create.php", this.build_postdata());
                    await this.$root.check_response(response, () => {
                        this.eventbus.$emit("SB", "create-post.msg.success-create-post", "success", 5);
                        this.dialog = false;
                        this.reset_create_post();
                        this.$emit("post_created");
                    }, "create-post.msg.error-create-post");
                }
            },
            reset_create_post() {
                this.valid              = false;
                this.current_date       = null;
                this.vpn_required       = false;
                this.key_giveaway       = false;
                this.base_game_url      = "";
                this.base_game_not_free = false;
                this.post_title         = "";
                this.post_description   = "";
                this.post_offer_url     = "";
                this.post_picture_url   = "";
                this.post_time_end      = "";
                this.expiration         = "";
                this.expiration_date    = null;
                this.expiration_time    = null;
            },

        },
        watch   : {
            async dialog() {
                if (this.dialog) {
                    // Reset Itemtype and Service Field
                    if (this.itemtypeid === null)
                        this.itemtypeid = this.$variables.v.itemtypes[0].id;
                    if (this.serviceid === null)
                        this.serviceid = this.$variables.v.services[0].id;

                    // Put current Date in Variable for Datepicker
                    this.current_date       = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
                    this.unknown_expiration = false;
                }
            },
        },
    }
</script>